<template>
  <NuxtLink to="/shortlist" class="cursor-pointer">
    <div
      class="bg-black text-white px-4 py-3 lg:w-2/3 bg-opacity-90 text-xs md:text-base align-middle rounded-sm shadow-md"
      v-if="shortlistStore.getCount() > 0"
    >
      <div class="justify-between flex align-middle items-center">
        <div><strong>Shortlist</strong></div>
        <div class="relative flex justify-center items-center">
        <i class="fa fa-star fa-2x "></i>
        <div class="absolute top-1.5 text-mv-red font-bold">{{ shortlistStore.getCount() }}</div>
        </div>
      </div>
    </div>
  </NuxtLink>
</template>


<script setup>
import { useShortlistStore } from "~/store/shortlist";

const shortlistStore = useShortlistStore();
const { addVehicleToShortlist, removeVehicle, clearShortlist, getCount } =
  useShortlistStore();
</script>